import React, { Component } from 'react';
import '../App.scss';

/* Bootstrap */
import Container from 'react-bootstrap/Container';

class About extends Component {
	render() {
		return (
	    <Container>
	    	About
	    </Container>
		);
	}
}

export default About;